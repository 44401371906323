<template>
  <div v-if="$can('manage', 'user') || $can('manage', 'all')">
    <!-- Header -->
    <b-row align-h="between" align-v="center" class="py-1">
      <b-col md="5" lg="8">
        <!-- Page title -->
        <h2 class="mb-2 mb-md-0">
          User
          <!-- <span>{{ $t('Admin') }}</span> -->
        </h2>
      </b-col>
      <b-col md="7" lg="4">
        <!-- Search -->
        <b-input-group>
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="searchKeyword"
            placeholder="Cari Email/Perusahaan"
          />
        </b-input-group>
      </b-col>
    </b-row>

    <!-- Table -->
    <b-card body-class="p-0">
      <!-- Header -->
      <b-card-header>
        <h4 class="mb-0">Daftar Akun User</h4>
        <b-row>
          <b-col>
            <b-button
              v-b-modal.new-user
              class="action-button"
              variant="outline-primary"
            >
              <feather-icon icon="PlusIcon" />
              <span> Tambah Pengguna</span>
            </b-button>
          </b-col>
          <!-- <b-col>
            <b-button
              v-b-modal.new-data-excel
              variant="warning"
              class="action-button mr-1"
            >
              <feather-icon icon="DatabaseIcon" />
              <span> Import Account Jalur Darat</span>
            </b-button>
          </b-col>
          <b-col>
            <b-button
              v-b-modal.new-data-excel-river
              variant="warning"
              class="action-button mr-1"
            >
              <feather-icon icon="DatabaseIcon" />
              <span> Import Account Jalur Sungai</span>
            </b-button>
          </b-col> -->
          <!-- <b-col>
            <b-button
              variant="success"
              class="action-button"
              :href="`${BACKEND_URL_1}/TEMPLATE_ACCOUNT.xlsx`"
            >
              <feather-icon icon="DownloadIcon" />
              <span> Template Account.xlsx</span>
            </b-button>
          </b-col> -->
        </b-row>
      </b-card-header>

      <!-- Body -->
      <b-progress
        v-if="fetchLoading"
        key="info"
        animated
        value="100"
        variant="info"
        :class="'progress-bar-info'"
      />
      <b-table
        id="table-transition"
        ref="usersTable"
        :fields="fields"
        :items="users"
        :no-border-collapse="true"
        class="m-0"
        responsive
        hover
        caption-top
        show-empty
        primary-key="number"
        bordered
        :tbody-transition-props="transProps"
      >
        <!-- No Data -->
        <template #empty>
          <p class="mb-0 py-2 text-center">Tidak ada data</p>
        </template>
        <template #cell(is_active)="data">
          <b-form-checkbox
            :checked="data.item.is_active"
            class="custom-control-primary"
            name="check-button"
            switch
            @change="updateIsActive(data.item)"
          >
            <span class="switch-icon-left">
              <feather-icon icon="UserCheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="UserMinusIcon" />
            </span>
          </b-form-checkbox>
        </template>

        <template #cell(type_account)="data">
          <b-badge href="#" :variant="variantType(data.item.type_account)">
            <feather-icon icon="LinkIcon" class="mr-25" />
            <span>{{ data.item.type_account }} </span>
          </b-badge>
        </template>
        <template #cell(role)="data">
          <b-badge href="#" :variant="variantRole(data.item.role)">
            <feather-icon icon="SettingsIcon" class="mr-25" />
            <span>{{ data.item.role }} </span>
          </b-badge>
        </template>
        <template #cell(visitor_abilities)="data">
          <sal-access v-for="(item, index) in data.item.visitor_abilities" :key="index">
            <b-badge href="#">
              <feather-icon icon="DatabaseIcon" class="mr-25" />
              <span>{{ item.action }} {{ item.subject }}</span>
            </b-badge>
            &nbsp;
          </sal-access>
          &nbsp;
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret>
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" />
            </template>

            <b-dropdown-item v-b-modal.edit-user @click="prepareUpdate(data)">
              <feather-icon icon="EditIcon" class="text-primary" />
              <span class="text-primary align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item v-b-modal.delete-user @click="prepareDelete(data)">
              <feather-icon icon="TrashIcon" class="text-danger" />
              <span class="text-danger align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <!-- Footer -->
      <b-card-footer>
        <b-row align-h="between" align-v="center">
          <b-col sm="2" class="mb-2 mb-sm-0">
            <!-- Select amount of data per page -->
            <b-form-select
              v-model="pagination.selectedAmount"
              :options="pagination.amountOptions"
            />
          </b-col>
          <b-col
            col
            class="d-flex justify-content-center justify-content-sm-end"
          >
            <!-- Pagination -->
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.totalItems"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              :per-page="pagination.selectedAmount"
            />
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>

    <!-- Delete user modal -->
    <b-modal
      id="delete-user"
      title="Hapus"
      cancel-variant="secondary"
      cancel-title="Tidak"
      ok-title="Iya"
      ok-variant="danger"
      body-class="py-2"
      footer-class="d-flex justify-content-center"
      centered
      @ok="onDelete"
    >
      <b-card-text class="text-center">
        <h4 class="mb-0">Hapus data {{ form.deleteMessage }} ?</h4>
      </b-card-text>
    </b-modal>

    <!-- New user modal -->
    <b-modal
      id="new-user"
      title="Tambah User"
      body-class="py-2"
      no-close-on-backdrop
      size="lg"
      hide-footer
    >
      <b-card-text>
        <!-- <h4 class="mb-2">
          User
        </h4> -->
        <validation-observer ref="newUserForm">
          <b-form @submit.prevent="onSubmit">
            <b-row>
              <b-col cols="6">
                <!-- Perusahaan -->
                <b-form-group label="Perusahaan" label-for="perusahaan">
                  <validation-provider
                    #default="{ errors }"
                    name="Perusahaan"
                    vid="perusahaan"
                    rules="required"
                  >
                    <b-form-input
                      id="perusahaan"
                      v-model="form.add.perusahaan"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <!-- Name -->
                <b-form-group label="Nama Lengkap" label-for="nama">
                  <validation-provider
                    #default="{ errors }"
                    name="Nama Lengkap"
                    vid="nama"
                    rules="required"
                  >
                    <b-form-input
                      id="nama"
                      v-model="form.add.full_name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <!-- Email -->
                <b-form-group label="Email" label-for="email">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="form.add.email"
                      name="email"
                      autocomplete="off"
                      :state="errors.length > 0 ? false : null"
                      placeholder="user@sal.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <!-- Phone -->
                <b-form-group label="Nomor HP" label-for="phone">
                  <validation-provider
                    #default="{ errors }"
                    name="Nomor HP"
                    vid="phone"
                    rules="required"
                  >
                    <b-form-input
                      id="phone"
                      v-model="form.add.phone"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="3">
                <!-- Hak Akses -->
                <b-form-group label="Hak Akses" label-for="role">
                  <validation-provider
                    #default="{ errors }"
                    name="Hak Akses"
                    vid="role"
                    rules="required"
                  >
                    <v-select
                      id="role"
                      v-model="form.add.roles"
                      :reduce="
                        (role) => [{ label: role.label, role_id: role.id }]
                      "
                      :options="form.roles"
                      :state="errors.length > 0 ? false : null"
                      @input="onChangeRoles"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="9">
                <b-form-group label="Sub Hak Akses" label-for="subrole">
                  <validation-provider
                    #default="{ errors }"
                    name="Sub Hak Akses"
                    vid="subrole"
                  >
                    <v-select
                      id="subrole"
                      v-model="form.add.subroles"
                      multiple
                      :reduce="
                        (subrole) => ({
                          label: subrole.label,
                          ability_id: subrole.id,
                          id: subrole.id
                        })
                      "
                      :options="form.subroles"
                      :state="errors.length > 0 ? false : null"
                      @input="onChangeSubRoles"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <!-- Password -->
                <b-form-group label="Kata Sandi" label-for="password">
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="Password"
                    rules="required|min:8"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="password"
                        v-model="form.add.password"
                        :type="password1FieldType"
                        name="password"
                        :state="errors.length > 0 ? false : null"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password1ToggleIcon"
                          @click="togglePassword1Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{
                      newPassword(errors[0])
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <!-- Confirm Password -->
                <b-form-group
                  label="Konfirmasi Kata Sandi"
                  label-for="confirm-password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Confirm password"
                    vid="confirm-password"
                    rules="required|confirmed:Password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="confirm-password"
                        v-model="form.add.confirmPassword"
                        :type="password2FieldType"
                        :state="errors.length > 0 ? false : null"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password2ToggleIcon"
                          @click="togglePassword2Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Save User -->
            <div class="text-right">
              <b-button
                type="submit"
                variant="primary"
                :disabled="newUserLoading"
                v-text="newUserLoading ? 'Menyimpan...' : 'Simpan'"
              />
            </div>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>

    <!-- Edit user modal -->
    <b-modal
      id="edit-user"
      title="Ubah User"
      body-class="py-2"
      hide-footer
      size="lg"
      no-close-on-backdrop
    >
      <b-card-text>
        <validation-observer ref="editUserForm">
          <b-form @submit.prevent="onUpdate">
            <b-row>
              <b-col cols="6">
                <!-- Perusahaan -->
                <b-form-group label="Perusahaan" label-for="perusahaan">
                  <validation-provider
                    #default="{ errors }"
                    name="Perusahaan"
                    vid="perusahaan"
                    rules="required"
                  >
                    <b-form-input
                      id="perusahaan"
                      v-model="form.edit.perusahaan"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <!-- Name -->
                <b-form-group label="Nama Lengkap" label-for="name">
                  <validation-provider
                    #default="{ errors }"
                    name="Name"
                    vid="name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="form.edit.full_name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <!-- Email -->
                <b-form-group label="Email" label-for="email">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="form.edit.email"
                      type="email"
                      autocomplete="off"
                      placeholder="user@sal.com"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <!-- Phone -->
                <b-form-group label="Nomor HP" label-for="phone">
                  <validation-provider
                    #default="{ errors }"
                    name="Nomor HP"
                    vid="phone"
                    rules="required"
                  >
                    <b-form-input
                      id="phone"
                      v-model="form.edit.phone"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col></b-row
            >

            <b-row>
              <b-col cols="3">
                <!-- Hak Akses -->
                <b-form-group label="Hak Akses" label-for="role">
                  <validation-provider
                    #default="{ errors }"
                    name="Hak Akses"
                    vid="role"
                    rules="required"
                  >
                    <v-select
                      id="role"
                      v-model="form.edit.roles"
                      :reduce="
                        (role) => [{ label: role.label, role_id: role.id }]
                      "
                      :options="form.roles"
                      :state="errors.length > 0 ? false : null"
                      @input="onChangeRoles"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="9">
                <!-- Hak Akses -->
                <b-form-group label="Sub Hak Akses" label-for="subrole">
                  <validation-provider
                    #default="{ errors }"
                    name="Sub Hak Akses"
                    vid="subrole"
                    rules="required"
                  >
                    <v-select
                      id="subrole"
                      v-model="form.edit.subroles"
                      multiple
                      :reduce="
                        (subrole) => ({
                          label: subrole.label,
                          ability_id: subrole.id,
                          id: subrole.id
                        })
                      "
                      :options="form.subroles"
                      :state="errors.length > 0 ? false : null"
                      @input="onChangeSubRoles"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col></b-row
            >

            <b-row>
              <b-col cols="6">
                <!-- Password -->
                <b-form-group
                  label="Kata Sandi ( kosongkan jika tidak ingin mengubah )"
                  label-for="password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="Password"
                    rules=""
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="password"
                        v-model="form.edit.new_password"
                        :type="password1FieldType"
                        name="password"
                        :state="errors.length > 0 ? false : null"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password1ToggleIcon"
                          @click="togglePassword1Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{
                      newPassword(errors[0])
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <!-- Confirm Password -->
                <b-form-group
                  label="Konfirmasi Kata Sandi"
                  label-for="confirm-password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Confirm password"
                    vid="confirm-password"
                    :rules="{
                      required: form.edit.new_password ? true : false,
                      confirmed: 'Password'
                    }"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="confirm-password"
                        v-model="form.edit.confirmPassword"
                        :type="password2FieldType"
                        :state="errors.length > 0 ? false : null"
                      />

                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password2ToggleIcon"
                          @click="togglePassword2Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col></b-row
            >
            <!-- Save User -->
            <div class="text-right">
              <b-button
                type="submit"
                variant="primary"
                :disabled="editUserLoading"
                v-text="editUserLoading ? 'Menyimpan...' : 'Simpan Perubahan'"
              />
            </div>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>

    <!-- land route modal user mass add -->
    <b-modal
      id="new-data-excel"
      title="Tambah Account ( Jalur Darat )"
      cancel-variant="secondary"
      ok-title="Simpan"
      cancel-title="Batal"
      ok-variant="success"
      body-class="py-2"
      footer-class="d-flex justify-content-center"
      centered
      @ok="() => onCreateWithExcel('darat')"
    >
      <b-card-text class="text-center">
        <b-avatar variant="light-warning" size="45">
          <feather-icon size="21" icon="TruckIcon" />
        </b-avatar>
        <label for="nilai" class="ml-1 mr-1">
          Menambah Data Account Sekaligus (Banyak)
        </label>
        <b-avatar variant="light-warning" size="45">
          <feather-icon size="21" icon="TruckIcon" />
        </b-avatar>
        <b-form-group>
          <v-dropzone
            id="files"
            ref="files"
            :options="{
              url: BACKEND_URL,
              method: `post`,
              maxFilesize: 5,
              maxFiles: 0,
              chunking: true,
              acceptedFiles: `application/vnd.ms-excel,excel,spreadsheetml,.xlsx`,
              autoProcessQueue: false
            }"
            duplicate-check
            use-custom-slot
            add-remove-links
            @vdropzone-file-added="addFile"
            @vdropzone-removed-file="removeFile"
            @vdropzone-complete="afterComplete"
          >
            <h6 class="mb-0">Masukkan File ACCOUNT_JALUR_DARAT.xlsx</h6>
          </v-dropzone>
        </b-form-group>
      </b-card-text>
    </b-modal>

    <!-- river route modal user mass add -->
    <b-modal
      id="new-data-excel-river"
      title="Tambah Account ( Jalur Sungai )"
      cancel-variant="secondary"
      ok-title="Simpan"
      cancel-title="Batal"
      ok-variant="success"
      body-class="py-2"
      footer-class="d-flex justify-content-center"
      centered
      @ok="() => onCreateWithExcel('sungai')"
    >
      <b-card-text class="text-center">
        <b-avatar variant="light-primary" size="45">
          <feather-icon size="21" icon="AnchorIcon" />
        </b-avatar>
        <label for="nilai" class="ml-1 mr-1">
          Menambah Data Account Sekaligus (Banyak)
        </label>
        <b-avatar variant="light-primary" size="45">
          <feather-icon size="21" icon="AnchorIcon" />
        </b-avatar>
        <b-form-group>
          <v-dropzone
            id="files"
            ref="files"
            :options="{
              url: BACKEND_URL,
              method: `post`,
              maxFilesize: 5,
              maxFiles: 0,
              chunking: true,
              acceptedFiles: `application/vnd.ms-excel,excel,spreadsheetml,.xlsx`,
              autoProcessQueue: false
            }"
            duplicate-check
            use-custom-slot
            add-remove-links
            @vdropzone-file-added="addFile"
            @vdropzone-removed-file="removeFile"
            @vdropzone-complete="afterComplete"
          >
            <h6 class="mb-0">Masukkan File ACCOUNT_JALUR_SUNGAI.xlsx</h6>
          </v-dropzone>
        </b-form-group>
      </b-card-text>
    </b-modal>
  </div>
</template>
<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BCardText,
  BTable,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BPagination,
  BFormSelect,
  BModal,
  BDropdown,
  BDropdownItem,
  BLink,
  BAvatar,
  BBadge,
  BProgress,
} from 'bootstrap-vue'
import VSelect from 'vue-select'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'

import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import flatPickr from 'vue-flatpickr-component'
import VDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import { subject } from '@casl/ability'
import { VueAutosuggest } from 'vue-autosuggest'
import useJwt from '@/auth/jwt/useJwt'
import '@core/scss/vue/libs/vue-autosuggest.scss'

export default {
  components: {
    BProgress,
    BBadge,
    BFormCheckbox,
    VueAutosuggest,
    Indonesian,
    flatPickr,
    BDropdownItem,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BCardText,
    BTable,
    BLink,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BPagination,
    BFormSelect,
    BDropdown,
    BModal,
    ValidationObserver,
    ValidationProvider,
    VSelect,
    VDropzone
  },
  data() {
    return {
      // dpconfig: {
      //   wrap: true,
      //   altInput: true,
      //   locale: Indonesian,
      //   defaultDate: 'today',
      // },
      BACKEND_URL_1: process.env.VUE_APP_BACKEND_URL,
      BACKEND_URL: `${process.env.VUE_APP_BACKEND_URL}/api/user/bulk/csv`,
      files: [],
      transProps: {
        name: 'flip-list'
      },
      maxUsername: 15,
      // Form
      form: {
        add: {
          perusahaan: '',
          full_name: '',
          // username: '',
          phone: '',
          email: '',
          group_id: '',
          group_name: '', // future new
          type_account: '',
          roles: [],
          subroles: [],
          password: '',
          confirmPassword: ''
        },
        edit: {
          id: 0,
          perusahaan: '',
          full_name: '',
          // username: '',
          phone: '',
          email: '',
          group_id: '', // old
          group_name: '', // future new
          group_old: '', // old
          group_create: true, // state create or ignore
          type_account: '',
          roles: [],
          subroles: [],
          new_password: '',
          confirmPassword: ''
        },
        deleteId: 0,
        deleteMessage: '',
        groups: [],

        type_account: [
          {
            label: 'Auto Approve'
          },
          {
            label: 'Pending Approve'
          }
        ],
        roles: [],
        subroles: [],
        tempSubRoles: []
      },
      stateOnReviewer: false,

      // Table fields, data, pagination
      fields: [
        {
          label: '#',
          key: 'number',
          sortable: true,
          thStyle: {
            minWidth: '1px',
            textAlign: 'center',
            verticalAlign: 'middle'
          }
        },
        { label: 'ID', key: 'id', sortable: true },
        { label: 'Email', key: 'email', sortable: true },
        { label: 'Status', key: 'is_active', sortable: true },
        // { label: 'Group', key: 'group.name', sortable: true },
        // { label: 'Type', key: 'type_account', sortable: true },
        { label: 'Hak Akses', key: 'visitor_role', sortable: true },
        { label: 'Sub Hak Akses', key: 'visitor_abilities', sortable: true },
        {
          label: 'Aksi',
          key: 'actions',
          thStyle: {
            minWidth: '10px',
            textAlign: 'center',
            verticalAlign: 'middle'
          }
        },
        { label: 'Perusahaan', key: 'perusahaan', sortable: true }
      ],
      users: [],
      pagination: {
        amountOptions: [5, 10, 15, 25, 50, 100],
        selectedAmount: 10,
        totalItems: 0,
        currentPage: 1
      },
      searchKeyword: '',
      timeoutDebounce: null,

      // loadings
      fetchLoading: false,
      newUserLoading: false,
      editUserLoading: false,

      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password'
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  },
  watch: {
    'pagination.currentPage': function () {
      this.fetchUsers()
    },
    'pagination.selectedAmount': function () {
      this.fetchUsers()
    },
    searchKeyword(keyword) {
      if (keyword.length <= 0 || keyword.length >= 3) {
        if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(() => this.fetchUsers(keyword), 300)
      }
    }
  },
  mounted() {
    document.body.style.zoom = '100%'
  },
  destroyed() {
    document.body.style.zoom = '100%'
  },
  created() {
    this.fetchRoles()
    this.fetchSubRoles()
    // this.fetchGroups()
    this.fetchUsers()
  },
  methods: {
    newPassword(message) {
      console.log('message: ', message)
      if (
        message ===
        'Your Password must contain at least one uppercase, one lowercase, one special character and one digit'
      ) {
        message =
          'Password setidaknya harus memiliki Satu Huruf Besar, Satu Huruf Kecil, Satu Spesial Karakter (!@# atau lainya) dan Satu Angka'
      }
      return message
    },
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === 'password' ? 'text' : 'password'
    },
    addFile(file) {
      console.log(file)
      this.files.push(file)
    },
    removeFile(removedFile) {
      this.files = this.files.filter(
        (file) => file.upload.uuid != removedFile.upload.uuid
      )
    },
    afterComplete(file) {
      console.log(`completd ${file}`)
    },
    onCreateWithExcel(flag) {
      const formData = new FormData()
      const files = []
      this.files.forEach((file) => {
        if (file instanceof File) {
          formData.append('file', file)
          useJwt.http
            .post(`visitor_user/bulk/csv/${flag}`, formData)
            .then((response) => {
              this.$alert({
                title: response.data.message,
                variant: 'success',
                icon: 'CheckIcon'
              })
              this.fetchUsers()
            })
            .catch((err) => {
              console.log(`error ${err}`)
              this.$alert({
                title: `${err.message}`,
                variant: 'danger',
                icon: 'XIcon'
              })
            })
        } else {
          files.push(file)
          console.log(`form data else : ${file}`)
        }
      })
    },
    getSuggestionValue(suggestion) {
      this.form.add.group_id = suggestion.item.id
      this.form.add.group_name = suggestion.item.name
      return suggestion.item.name
    },
    getSuggestionValueEdit(suggestion) {
      this.form.edit.group_id = suggestion.item.id
      this.form.edit.group_name = suggestion.item.name
      this.form.edit.group_create = false // don't create a group
      console.log(suggestion.item)
      return suggestion.item.name
    },
    customedFields() {
      return this.fields
      // const { fields } = this

      // return fields.map(field =>
      //   // if (field.key === 'number') {
      //   //   field.thStyle = { verticalAlign: 'middle' }
      //   //   field.thClass = 'number'
      //   // }
      //   // if ('minWidth' in field) {
      //   //   field.thStyle = {
      //   //     minWidth: `${field.minWidth}`,
      //   //   }
      //   // }
      //   field)
    },
    resolveLastObjectKey(path) {
      const p = path.split('.')
      return p[p.length - 1]
    },
    variantRole(data) {
      const dataVariant = {
        host: 'danger',
        client: 'info',
        security: 'warning'
      }
      return dataVariant[data]
    },
    variantType(data) {
      const dataVariant = {
        'Auto Approve': 'primary',
        'Pending Approve': 'warning',
        '': 'info',
        null: 'info'
      }
      return dataVariant[data]
    },
    variantGroup(data) {
      //
      // const dataVariant = {
      //   TEM: 'primary',
      //   PATRA: 'warning',
      //   // '': 'info',
      //   // null: 'info',
      // }
      // return dataVariant[data]
      // return 'primary'
    },

    fetchUsers(keyword = '') {
      this.fetchLoading = true
      try {
        useJwt.http
          .get('visitor_user', {
            params: {
              page: this.pagination.currentPage - 1,
              size: this.pagination.selectedAmount,
              keyword
            }
          })
          .then((response) => {
            console.log('object:::', response)
            this.users = response.data.visitor_users.map((user, id) => {
              user.index = id
              user.number = id + 1
              user.name = user.full_name
              user.visitor_role = user.visitor_roles.length ? user.visitor_roles[0].name : '-'

              user.visitor_abilities = user.visitor_abilities.map((item) => {
                let act = item.action
                let sub = item.subject
                // if (act === 'BASE OIL') {
                //   act = '⛟ Segment BASE OIL'
                //   sub = ''
                // }
                if(act === 'Sarana Abadi Lestari'){
                  act = '🕵️‍♂️ Approval Sarana Abadi Lestari'
                  sub = ''
                }
                if(act === 'Palaran Indah Lestari'){
                  act = '🕵️‍♂️ Approval Palaran Indah Lestari'
                  sub = ''
                }
                if(act === 'Sarana Utama Lestari'){
                  act = '🕵️‍♂️ Approval Sarana Utama Lestari'
                  sub = ''
                }
                if(act === 'Sarana Palaran Lestari'){
                  act = '🕵️‍♂️ Approval Sarana Palaran Lestari'
                  sub = ''
                }
                if(act === 'Pertamina Patra Niaga'){
                  act = '🕵️‍♂️ Approval Pertamina Patra Niaga'
                  sub = ''
                }
                if(act === 'Schlumberger'){
                  act = '🕵️‍♂️ Approval Schlumberger'
                  sub = ''
                }
                if(act === 'Palaran Energi Semesta'){
                  act = '🕵️‍♂️ Approval Palaran Energi Semesta'
                  sub = ''
                }
                if (act === 'manage') {
                  switch (sub) {
                    case 'all':
                      act = '🔑 All Access'
                      sub = ''
                      break
                    case 'user':
                      act = '🧑‍💻 Management User'
                      sub = ''
                      break

                    default:
                      break
                  }
                }
                return {
                  ...item,
                  action: act,
                  subject: sub
                }
              })

              return user
            })

            this.pagination.totalItems = response.data.total_items
          })
          .catch(() =>
            this.$alert({
              title: 'Telah terjadi kesalahan.',
              variant: 'danger',
              icon: 'XIcon'
            })
          )
          .finally(() => {
            this.fetchLoading = false
          })
      } catch (error) {
        console.log('get fetch an error: ', error)
      }
    },
    searchGroups(text) {
      if (text) {
        clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(() => {
          useJwt.http
            .get(`visitor_group/search/${text}`)
            .then((response) => (this.form.groups = response.data.groups))
          this.form.edit.group_create = true
        }, 300)
      }
    },
    fetchGroups() {
      useJwt.http.get('visitor_group').then((response) => {
        this.form.groups = response.data.groups
      })
    },
    fetchRoles() {
      useJwt.http
        .get('visitor_role')
        .then(
          (response) =>
            (this.form.roles = response.data.visitor_roles.map(
              ({ id, name: label }) => ({ label, id })
            ))
        )
    },
    fetchSubRoles() {
      useJwt.http.get('visitor_ability').then((response) => {
        this.form.subroles = response.data.visitor_abilitys
          .map((ability) => {
            const act = ability.action
            const sub = ability.subject
            let label = `${`${act} ${sub}`}`

            if(act === 'Sarana Abadi Lestari'){
              label = "🕵️‍♂️ Approval Sarana Abadi Lestari"
            }
            if(act === 'Palaran Indah Lestari'){
              label = "🕵️‍♂️ Approval Palaran Indah Lestari"
            }
            if(act === 'Sarana Utama Lestari'){
              label = "🕵️‍♂️ Approval Sarana Utama Lestari"
            }
            if(act === 'Sarana Palaran Lestari'){
              label = "🕵️‍♂️ Approval Sarana Palaran Lestari"
            }
            if(act === 'Pertamina Patra Niaga'){
              label = "🕵️‍♂️ Approval Pertamina Patra Niaga"
            }
            if(act === 'Schlumberger'){
              label = "🕵️‍♂️ Approval Schlumberger"
            }
            if(act === 'Palaran Energi Semesta'){
              label = "🕵️‍♂️ Approval Palaran Energi Semesta"
            }
            if (act === 'manage') {
              switch (sub) {
                case 'all':
                  label = '🔑 All Access'
                  break
                case 'user':
                  label = '🧑‍💻 Management User'
                  break
              }
            }
            const abilitiesData = {
              label: label,
              ability_id: ability.id,
              id: ability.id
            }
            return abilitiesData
          })
          .sort((a, b) => a.label.localeCompare(b.label)) // Sort by label (ascending)

        // this.form.subroles = response.data.visitor_abilitys.map((ability) => ({
        //   label: `${`${ability.action} ${ability.subject}`}`,
        //   ability_id: ability.id,
        //   id: ability.id
        // }))
      })
    },
    onSubmit() {
      this.$refs.newUserForm.validate().then((success) => {
        if (success) {
          this.newUserLoading = true
          useJwt.http
            .post('visitor_user', this.form.add)
            .then((response) => {
              this.newUserLoading = false
              this.$alert({
                title: response.data.message,
                variant: 'success',
                icon: 'CheckIcon'
              })

              this.form.add.perusahaan = ''
              this.form.add.full_name = ''
              this.form.add.group_id = ''
              this.form.add.group_name = '' // future new
              this.form.add.group_old = '' // old
              this.form.add.phone = ''
              this.form.add.email = ''
              this.form.add.type_account = ''
              this.form.add.roles = []
              this.form.add.subroles = []
              this.form.add.password = ''
              this.form.add.confirmPassword = ''

              this.$bvModal.hide('new-user')
              this.$router.push({ name: 'sa-users' })
              this.newUserLoading = false
            })
            .catch((error) => {
              this.$alert({
                title: `${error.response.data.message}`,
                variant: 'danger',
                icon: 'XIcon'
              })
            })
            .finally(() => {
              this.fetchUsers()
            })
        }
      })
    },
    updateRole() {
      return useJwt.http.put(`visitor_user/role/${this.form.edit.id}`, {
        roles: this.form.edit.roles.map((role) => ({
          visitor_role_id: role.role_id,
          visitor_user_id: this.form.edit.id
        }))
      })
    },
    updateSubRole() {
      return useJwt.http.put(`visitor_user/ability/${this.form.edit.id}`, {
        subroles: this.form.edit.subroles.map((subrole) => ({
          visitor_ability_id: subrole.ability_id,
          visitor_user_id: this.form.edit.id
        }))
      })
    },
    updateIsActive(item) {
      try {
        const value = !item.is_active
        // console.log('update: ', this.users[item.index]);
        this.users[item.index].is_active = value
        const idUser = this.users[item.index].id

        return useJwt.http
          .put(`visitor_user/${idUser}`, {
            is_active: value
          })
          .then((res) => {
            this.$alert({
              title: res.data.message,
              variant: 'success',
              icon: 'CheckIcon'
            })
          })
      } catch (error) {
        console.log('an error for updateIsActive: ', error)
      }
    },
    onSelectedGroup(option) {
      this.form.edit.group_name = option.item.name
      this.form.edit.group_id = option.item.id
      this.form.edit.group_create = false // don't create a group
    },
    onUpdate() {
      this.$refs.editUserForm.validate().then((success) => {
        if (success) {
          this.editUserLoading = true
          // group
          try {
            if (this.form.edit.group_create === true) {
              // create a group
              if (this.form.edit.group_name !== this.form.edit.group_old) {
                this.form.edit.group_id = this.form.edit.group_name
              }
            }
          } catch (e) {}

          Promise.all([
            useJwt.http.put(
              `visitor_user/${this.form.edit.id}`,
              this.form.edit
            ),
            this.updateRole(),
            this.updateSubRole()
          ])
            .then((response) => {
              this.$alert({
                title: 'user updated',
                variant: 'success',
                icon: 'CheckIcon'
              })

              this.form.edit.new_password = ''
              this.form.edit.confirmPassword = ''
              this.form.edit.group_id = '' // old
              this.form.edit.group_name = '' // future new
              this.form.edit.group_old = '' // old

              this.fetchUsers()
              this.$bvModal.hide('edit-user')
            })
            .catch((error) =>
              this.$alert({
                title: `${error.response.data.message}`,
                variant: 'danger',
                icon: 'XIcon'
              })
            )
            .finally(() => {
              this.editUserLoading = false
            })
        }
      })
    },
    prepareUpdate(data) {
      this.form.edit.id = data.item.id
      this.form.edit.perusahaan = data.item.perusahaan
      this.form.edit.full_name = data.item.full_name
      this.form.edit.phone = data.item.phone
      this.form.edit.email = data.item.email

      // group
      try {
        this.form.edit.group_id = data.item.visitor_group_id
        this.form.edit.group_name = data.item.visitor_group.name
        this.form.edit.group_old = this.form.edit.group_name
      } catch (e) {}

      this.form.edit.type_account = data.item.type_account
      this.form.edit.roles = data.item.visitor_roles.map((role) => ({
        label: role.name,
        role_id: role.id
      }))
      this.form.edit.subroles = data.item.visitor_abilities.map((subrole) => ({
        label: `${`${subrole.action}  ${subrole.subject}`}`,
        ability_id: subrole.id,
        id: subrole.id
      }))
      //
      // if (this.form.edit.roles[0].label.includes('reviewer')) {
      //   this.stateOnReviewer = true
      //   this.form.edit.departments = ''
      // } else {
      //   this.stateOnReviewer = false
      //   this.form.edit.departments = data.item.departments.map(department => ({ label: department.program_studi, department_id: department.id }))
      // }
    },
    onDelete() {
      this.fetchLoading = true

      useJwt.http
        .delete(`visitor_user/${this.form.deleteId}/0`)
        .then((response) => {
          this.$alert({
            title: response.data.message,
            variant: 'success',
            icon: 'CheckIcon'
          })
          this.fetchUsers()
        })
        .catch(() =>
          this.$alert({
            title: 'Gagal menghapus user.',
            variant: 'danger',
            icon: 'XIcon'
          })
        )
        .finally(() => {
          this.fetchLoading = false
        })
    },
    prepareDelete(data) {
      const { id, full_name } = data.item
      this.form.deleteId = id
      this.form.deleteMessage = full_name
    },
    onChangeRoles(obj) {
      //
      // if (obj[0].label.toString().includes('reviewer')) {
      //   this.stateOnReviewer = true
      //   this.form.edit.departments = ''
      //   this.form.add.departments = ''
      // } else this.stateOnReviewer = false
    },
    onChangeSubRoles(obj) {
      // if (obj.length === 1) {
      //   this.form.edit.subroles = this.form.tempSubRoles
      // } else {
      //   this.form.tempSubRoles = obj[0]
      // }
      //
      // if (obj[0].label.toString().includes('reviewer')) {
      //   this.stateOnReviewer = true
      //   this.form.edit.departments = ''
      //   this.form.add.departments = ''
      // } else this.stateOnReviewer = false
    }
  }
}
</script>
<style>
table#table-transition .flip-list-move {
  transition: transform 0.4s;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
